.navbar {
  background-color: transparent;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 110px;
}

.navbar ul {
  display: flex;
  gap: 51px;
}

.navbar-items {
  color: #000;
  font-size: 22px;
  font-family: PF Din Text Universal;
  font-weight: 700;
}


@media only screen and (max-width: 600px) {

  .navbar {
    flex-direction: column;
  }

  .navbar ul {
    flex-direction: column;
    align-items: center;
  }
}