.footer1 {
    background-image: url('../../../assets/fonts/backyellow.png');
    height: 202px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:210px;
    
}

.footer1 ul {
    display: flex;
    flex-direction: column;
    gap:8px;

}


.footer2 {

    background-color: white;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: right;
    font-size: 20px;
    font-family: PF Din Text Universal;
    font-weight: 500;

}

.footer-item {
    color: #000;
    text-align: right;
    font-size: 20px;
    font-family: PF Din Text Universal;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.footer-logo{
    height: 40.37px;
    width: 40.37px;
}