

.about {
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 150px;
    padding-right: 115px;
    padding-left: 115px;
    
   
   z-index: 9999;
    
    
}

.step {
    color: #000;
    text-align: right;
    font-size: 40px;
    font-family: PF Din Text Universal;
    font-weight: 700;
    padding-bottom: 25px;
}

.we {
    color: #000;
    text-align: right;
    font-size: 32px;
    font-family: PF Din Text Universal;
    font-weight: 700;
    padding-bottom: 10px;
}

.para {
    color: #323232;
    text-align: right;
    font-size: 20px;
    font-family: PF Din Text Universal;
    font-weight: 700;
    display: flex;
    width: 510px;
    flex-direction: column;
    padding-bottom: 35px;

}

.jouer {

    color: #000;
    text-align: right;
    font-size: 32px;
    font-family: PF Din Text Universal;
    font-weight: 700;
    padding-bottom: 20px;
}

.icons {
    display: flex;
    gap: 10px;
    align-items: end;
     
}

.all-para {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    
}

/* Responsive styles */
@media (max-width: 600px) {
    .about {
      flex-direction: column;
      text-align: center;
      gap: 50px;
    }
  
    .icons {
      justify-content: center;
    }
  
    .all-para {
      padding-right: 0;
    }
    
    .para {
      width: 100%;
    }
    
    .step,
    .we,
    .para,
    .jouer {
      text-align: center;
      padding-bottom: 15px;
    }
    
    .step {
      font-size: 30px;
    }
    
    .we {
      font-size: 24px;
    }
    
    .para {
      font-size: 16px;
    }
    
    .jouer {
      font-size: 24px;
    }
  }