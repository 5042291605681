.backgroundff {
    background-image: url('../../../assets/fonts/backgroundff.png');
    background-color: #F9D923;
    background-repeat: no-repeat;
    background-size: cover;
  
}

.titer {
    color: #000;
    text-align: right;
    font-size: 32px;
    font-family: PF Din Text Universal;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.nav2 {
    background-color: transparent;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;


}

.nav-item1 {
    color: #0049C9;
    text-align: right;
    font-size: 24px;
    font-family: PF Din Text Universal;
    font-weight: 500;
}

.trust {
    background-image: url('../../../assets/images/trust.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    
    height: 270px;
    
    
}

.messages{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    
    
     
}

.msgL,
.msgR{
    display: flex;
    flex-direction: column;
    height: 100%;

}