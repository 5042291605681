
.background{
    background-image: url('../../../assets/fonts/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 120vh;
    width: 100%;
   position: relative;
  
}
.green{
    background-image: url('../../../assets/fonts/green.png');
    background-repeat: no-repeat;
    background-size: cover;
   height: 100%;
   width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    
    
}


.backgroundOO{
   
    background-image: url('../../../assets/fonts/backg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
}
.topHome{
    position: relative;
    z-index: 9999999;
}
