.background2 {
    background-image: url('../../../assets/fonts/cercelll.png'),url('../../../assets/fonts/hamouda.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 778px;
    width: 100%;



}
.background3{
    background-image: url('../../../assets/fonts/hamouda.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 778px;
    width: 100%;
}
.nav {
    background-color: white;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.nav-item {
    color: #000;
    text-align: right;
    font-size: 32px;
    font-family: PF Din Text Universal;
    font-weight: 700;
}

.icons1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 60px;
    


}

.icons2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 60px;
    text-align: center;
}


.iconss {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap:40px;
    
    

}


.text1 {
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
   

    color: #000;
    font-size: 24px;
    font-family: "PF Din Text Universal";
    font-weight: 500;
    height: 250px;
    justify-content: space-between;

}
.text1 img{
    height: 200px;
    width: 200px;
    object-fit: contain;
}