

.download {
    background-image: url('../../../assets/fonts/BACKGROUNDD.png');
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 250px;
 
    padding-right: 60px;
    padding-left: 10px;
}

.stepp {
    color: #FFF;
    text-align: right;
    font-size: 48px;
    font-family: PF Din Text Universal;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 30px;
}



.paraa {
    color: #FFF;
    text-align: right;
    font-size: 24px;
    font-family: PF Din Text Universal;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 20px;

}


.iconsss {
    display: flex;
    flex-direction: row;
    gap: 10px;
    

  
}

.alll-para {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    padding-right: 60px;
   
}