

.all {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap:60px;
    background-image: url('../../../assets/fonts/backg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    

}

.sec1,.sec2
{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 180px;
   

}
.sec3{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 25px;
    padding-right: 20px;
}
.sec4 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 180px;
    padding-bottom: 80px;


}

.pa {

    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;

}

.mmapp {
    color: #000;
    text-align: right;
    font-size: 32px;
    font-family: PF Din Text Universal;
    font-weight: 700;
    padding-bottom: 80px;
}

.ttapp {
    color: #0049C9;
    text-align: right;
    font-size: 24px;
    font-family: PF Din Text Universal;
    font-weight: 500;
    padding-bottom: 10px;

}

.parag {
    color: #000;
    text-align: right;
    font-size: 18px;
    font-family: PF Din Text Universal;
    display: flex;
    width: 536px;
    flex-direction: column;



}

.frame {
    width: 370.81px;
    height: 321px;

}

.frame2 {
    
    width: 408px;
    height: 321px;
   

}

.pyramide {
    width: 563px;
    height: 421px;

}

.parents {
    width: 408px;
    height: 366px;
    
}