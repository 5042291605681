.nna {
    background-image: url('../../../assets/fonts/backg.png');
    padding-bottom: 60px;

}


.titerr {
    color: #000;
    text-align: right;
    font-size: 32px;
    font-family: PF Din Text Universal;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: end;
    padding-right: 139px;
    padding-top: 70px;

}

.rec1 {
    background-image: url('../../../assets/images/layer1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;



}

.rec2 {
    background-image: url('../../../assets/images/layer2.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: contain;


}

.rec3 {
    background-image: url('../../../assets/images/layer3.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: contain;


}

.rec1,
.rec2,
.rec3 {
    padding: 20px 20px;
}

.rectangles {

    display: flex;
    justify-content: space-around;
    
    padding-right: 125px;
    padding-left: 125px;
    gap:20px;

}

.p1 {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-family: PF Din Text Universal;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 15px;


}

.number {
    color: #404040;
    text-align: center;
    font-size: 20px;
    font-family: PF Din Text Universal;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 40px;


}

.box1 {
    padding-top: 60px;
}

.socials{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
.contact-logo{
    
    height: 68.42px;
    width: 68.42px;
}